/* eslint-disable prettier/prettier */
// eslint-disable-next-line prettier/prettier
import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Cory Wong | RBC Senior Business Analyst', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'My name is Cory Wong. I graduated from Ryerson University studying Business Technology Management with a minor in economics. I am currently working at RBC as a Senior Business Analyst.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: '',
  subtitle: '',
  subtitle2: '',
  subtitle3: '',
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'I am an experienced professional currently working at RBC P&CB as a Senior Business Analyst with an undergraduate degree in Business Technology Management from Ryerson University. My great passion for business and data analysis has allowed me to lead many business initiatives across different pillars of banking revolving HR, Tech Ops, Investor Services, Finance, and Personal & Commercial Banking. Having worked in a variety of regulatory projects has enabled me to build an aptitude in compliance, risk, and policy.',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.linkedin.com/in/cory-wong-487b08131/', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'Senior Business Analyst',
    datesworked: 'December 2022 - Present',
    info: 'Currnetly working to implement Total Cost Reporting solutions and CIRO/CIPF Rebrand Changes for Royal Mutual Fund Trust and RBC Direct Investing. Successfully brought Royal Mutual Fund Trust into compliance for Unclaimed Properties Regulatory Program.',
    info2: 'Key Competencies & Skills:',
    info3: 'Risk Focused',
    info4: 'Keen Policy Reader',
    info5: 'Problem Solver',
    info6: 'Effective Communicator',
    info7: 'Attention to Details',
    url: 'https://www.rbcroyalbank.com/personal.html',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'Business Analyst',
    datesworked: 'November 2021 - December 2022',
    info: 'While working in Investor and Treasury Services, I lead change management of an automated access management & attestation solutions for Canadian, UK, Luxembourg, and Malysian employees. This lead to a reductions of large audit findings by PwC. This was achieved through our partnership with the Chief Data Office through support of UAT, PIV, use cases, requirements gathering, and business rule analysis activities.',
    info2: 'Key Competencies & Skills:',
    info3: 'Effective Communicator',
    info4: 'Innovative',
    info5: 'Problem Solver',
    info6: 'Data Interpreter',
    info7: 'Adaptable to Different Environments',
    url: 'https://www.rbcroyalbank.com/personal.html',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'Supplier & Application Analyst',
    datesworked: 'April 2021 - November 2021',
    info: 'Within the Financial Shared Services team I worked under multiple groups as a dynamic resource. Most notibily I worked with the CDIC (Canadian Deposit Insurance Corporation) project team on implementing processes for identifying and provide data insights to reconcile general ledge balances during simulated bank failures. The core duties of the role made me responsible facilitating and coordinating resolution between RBC LOB’s & suppliers relating to access management along with supporting the Vendor Operations Manager to meet compliance with internal & regulatory requirements. Other duties I perform in this role entail working with security administration facility (SECAF), eGL suite of applications such as PeopleSoft, FIBRS and SAP Finance as a BSA.',
    info2: 'Key Competencies & Skills:',
    info3: 'Effective Communicator',
    info4: 'Innovative',
    info5: 'Problem Solver',
    info6: 'Data Interpreter',
    info7: 'Adaptable to Different Environments',
    url: 'https://www.rbcroyalbank.com/personal.html',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'Business Analyst/Project Manager Jr.',
    datesworked: 'September 2020 - April 2021',
    info: 'Recognized  for my innovation during co-op, I was offered a position at RBC to continue working through my final year of study as a Business Analyst/ Project Management Jr. within the Global Mobility Advisory Group. I developed streamlined business processing solutions as well as VBA automation tools for their emailing, reporting, processing, and data clean up needs. These automation tools allowed for greater efficiency within the team as well as protecting the bank from regulatory risks due to human errors. The benefit of the streamlined processes and automation tools I have created for the team amount to around +1256 hours in saved working labour costs on an annual basis.',
    info2: 'Key Competencies & Skills:',
    info3: 'Business Process Analysis',
    info4: 'Business and Strategic Thinking Acumen',
    info5: 'Data Interpreter',
    info6: 'Data Analysis',
    info7: 'Problem Solver',
    url: 'https://www.rbcroyalbank.com/personal.html',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'Business Analyst & Data Steward Co-op',
    datesworked: 'January 2020 - August 2020',
    info: 'In this role at RBC, I worked with subject matter experts to identify application deficiencies for areas where we could implement streamlined processes. Working with the HRBPs and IT Shared Services, I conducted accurate and timely investigations when incidents occurred for SAP, Success Factors LMS, and Service Now by providing solutions and support for production problems. As part of the Data Stewards team, I aided in the data audit process to ensure company governance rules were being followed and developed data scrubbing, web scrapping, reporting, and email automation automation tools to speed up this process saving around +450 hours trimonthly in working labour costs.',
    info2: 'Key Competencies & Skills:',
    info3: 'Business Process Analysis',
    info4: 'Business and Strategic Thinking Acumen',
    info5: 'Data Interpreter',
    info6: 'Data Analysis',
    info7: 'Problem Solver',
    url: 'https://www.rbcroyalbank.com/personal.html',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'CIBC LOGO.jpg',
    title: 'Application Security Analyst Co-op',
    datesworked: 'May 2019 - August 2019',
    info: 'In this role at CIBC, I was mainly responsible for managing of access control of many impacting applications across CIBC, requiring a high aptitude for attention to detail and exceptional time management skills. I was able help management identify staffing, communication, and process deficiencies within my team leading to major cost savings estimated to be around $500,000 within a 6-month period. Before my term at CIBC ended I was able to apply my critical thinking skills to implement an innovative VBA macro to help my department speed up the administrative process for repetitive reporting and emailing tasks.',
    info2: 'Key Competencies & Skills:',
    info3: 'Analytical and Crititcal Thinker',
    info4: 'Innovative',
    info5: 'Accountable',
    info6: 'Keen Attention to Detail',
    info7: 'Reliable',
    url: 'https://www.cibc.com/en/personal-banking.html',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Benefits Processing Specialist Co-op',
    datesworked: 'May 2018 - December 2018',
    info: 'While working at the Healthcare of Ontario Pension Plan I was gain an aptitude for analyzing financial information. This position required critical thinking and analytical skills to reconcile and verify financial figures, demonstrating my meticulous attention to detail and exceptional time management ability. Throughout the course of the term I was entrusted to finalize and oversee the payments of millions of dollars for terminated members.',
    info2: 'Key Competencies & Skills:',
    info3: 'Aptitude for Analyzing Financial Information',
    info4: 'Data Analysis',
    info5: 'Keen Attention to Details',
    info6: 'Innovative',
    info7: 'Accountable for Actions',
    url: 'https://hoopp.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'ryerson.jpg',
    title1: 'Education',
    title2: 'Bachelor of Commerce – Business Technology Management Co-op',
    title3: 'Minor: Economics',
    title: 'Toronto Metropolitan University | Ryerson University',
    datesworked: 'September 2016 - April 2021',
    info: 'The Business Technology Management program has enabled me to clearly see both the view points and needs of the business and technology side of an organization.',
    info2: 'Key Courses:',
    info3: 'Business Process Design',
    info4: 'Business Intelligence and Analytics',
    info5: 'Decision Analysis',
    info6: 'Strategy, Management and Acquisition',
    info7: 'Infomation Systems Project Management',
    url: 'https://www.ryerson.ca/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/cory-wong-487b08131/',
    },

  ],
};

export const bgimage = {
  img: 'bg.jpg',
};
